<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <v-form @submit.prevent="handleSubmit(submit)">
      <div class="form-wrapper">
        <BackendErrors :backend-errors="backendErrors" />
        <BaseInput
          v-model="formData.name"
          rules="min:3|max:255|required"
          :label="$t('competencyProfile.create.name')"
        />
        <BaseSelect
          v-model="formData.selectedCompetences"
          rules="select"
          :items="competences"
          :label="$t('competencyProfile.create.competences')"
          :placeholder="$t('competencyProfile.create.competences_hint')"
          item-text="name"
          item-value="id"
          persistent-hint
          multiple
          chips
          searchable
          :loading="isLoading"
          :disabled="isLoading"
        />
      </div>
      <EditButtons
        :edit-mode="editMode"
        :is-sending="isSending"
        @submit="submit"
        @cancel="cancel"
      />
    </v-form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import BackendErrors from '@/components/Dashboard/Partials/BackendErrors/BackendErrors';
import BaseInput from '@/components/Form/BaseInput';
import BaseSelect from '@/components/Form/BaseSelect';
import EditButtons from '@/components/Dashboard/Partials/EditButtons/EditButtons';

export default {
  name: 'CompetencyProfileForm',
  components: {
    ValidationObserver,
    EditButtons,
    BackendErrors,
    BaseInput,
    BaseSelect,
  },
  props: {
    competencyProfile: {
      type: Object,
      default: () => {},
    },
    competences: {
      type: Array,
      default: () => [],
    },
    backendErrors: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        name: '',
        selectedCompetences: '',
      },
    };
  },
  created() {
    if (this.editMode) {
      this.formData.name = this.competencyProfile.name;
      this.formData.selectedCompetences = this.competencyProfile.competences.data.map((competence) => competence.id);
    }
  },
  methods: {
    cancel() {
      this.$emit('handle-cancel');
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }
      this.$emit('handle-submit', this.baseFormData());
    },
    baseFormData() {
      const baseFormData = {
        name: this.formData.name,
        competences: this.formData.selectedCompetences,
      };
      if (!this.editMode) {
        return baseFormData;
      }

      return { ...baseFormData, competencyProfileId: this.$route.params.competencyProfileId };
    },
  },
};
</script>
