<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <v-form @submit.prevent="handleSubmit(submit)">
      <div class="form-wrapper">
        <BackendErrors :backend-errors="backendErrors" />
        <v-row>
          <v-col
            sm="12"
            lg="6"
            class="pr-lg-8"
          >
            <slot />
            <BaseInput
              v-model="formData.title"
              rules="min:3|max:255|required"
              :label="$t('stages.game_message.title')"
            />
            <BaseRichTextEditor
              v-model="formData.body"
              rules="min:3|max:60000|required"
              :label="$t('stages.game_message.body')"
            />
            <BaseImageUploader
              v-model="formData.sideImage"
              rules="size:10240|extensions:jpeg,jpg,png,gif"
              :label="$t('stages.game_message.side_image')"
              :vid="$t('stages.game_message.side_image')"
            />
            <v-alert
              icon="mdi-information-outline"
              color="grayDark"
              text
            >
              <span class="body-2">
                {{ $t('stages.game_message.side_image_requirements') }}
              </span>
            </v-alert>
          </v-col>
          <v-col
            sm="12"
            lg="6"
          >
            <p class="d-flex align-center title black--text pb-2">
              <span>{{ $t('stages.game_message.feedback_title') }}</span>
            </p>
            <BaseInput
              v-model="formData.feedback.body"
              :rules="feedbackValidationRules()"
              :label="$t('stages.game_message.feedback')"
            />
            <BaseImageUploader
              v-model="formData.feedback.image"
              :rules="imageValidationRules()"
              :label="$t('stages.game_message.feedback_image')"
              :vid="$t('stages.game_message.feedback_image')"
            />
          </v-col>
        </v-row>
      </div>
      <EditButtons
        :edit-mode="editMode"
        :is-sending="isSending"
        @submit="submit"
        @cancel="cancel"
      />
    </v-form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import BackendErrors from '@/components/Dashboard/Partials/BackendErrors/BackendErrors';
import BaseRichTextEditor from '@/components/Form/BaseRichTextEditor';
import BaseInput from '@/components/Form/BaseInput';
import BaseImageUploader from '@/components/Form/BaseImageUploader';
import EditButtons from '@/components/Dashboard/Partials/EditButtons/EditButtons';
import { DELETE } from '@/names/global.names';

export default {
  name: 'GameMessage',
  components: {
    ValidationObserver,
    EditButtons,
    BackendErrors,
    BaseRichTextEditor,
    BaseInput,
    BaseImageUploader,
  },
  props: {
    backendErrors: {
      type: Array,
      default: () => [],
    },
    currentStage: {
      type: String,
      required: true,
    },
    stage: {
      type: Object,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        title: '',
        body: '',
        sideImage: null,
        feedback: {
          body: '',
          image: '',
        },
      },
    };
  },
  created() {
    if (this.editMode) {
      this.formData.title = this.stage.stageable.data.title;
      this.formData.body = this.stage.stageable.data.body;
      this.formData.sideImage = this.stage.side_image_url;
      if (this.stage.stageable.data.feedback) {
        this.formData.feedback.body = this.stage.stageable.data.feedback.data.body;
        this.formData.feedback.image = this.stage.stageable.data.feedback.data.image_url;
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('handle-cancel');
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }

      this.$emit('handle-submit', this.baseFormData());
    },
    baseFormData() {
      const formData = new FormData();
      const { gameId, stageId } = this.$route.params;
      formData.set('type', this.currentStage);
      formData.set('title', this.formData.title);
      formData.set('body', this.formData.body);
      formData.set('side_image', this.formData.sideImage);
      if (this.formData.feedback.body) {
        formData.set('feedback[body]', this.formData.feedback.body);
        formData.set('feedback[image]', this.formData.feedback.image);
      }
      if (this.editMode) {
        formData.set('_method', 'PUT');

        return { gameId, stageId, params: formData };
      }

      return { gameId, params: formData };
    },
    imageValidationRules() {
      return `${
        (this.formData.feedback.body && !this.editMode)
        || (this.formData.feedback.body && this.formData.feedback.image === DELETE)
          ? 'image_required|'
          : ''
      }size:10240|extensions:jpeg,jpg,png,gif`;
    },
    feedbackValidationRules() {
      return `${
        this.formData.feedback.image && this.formData.feedback.image !== DELETE ? 'required|' : ''
      }min:3|max:255`;
    },
  },
};
</script>
