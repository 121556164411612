export default {
  title: 'Text pages',
  name: 'Name',
  updated_at: 'Update date',
  no_results: 'Pages not found',
  edit: 'Edit',
  create: {
    body: 'Content',
    variables: 'Tags to use',
    button: 'Label of the Continue button',
    side_image: 'Side image',
    side_image_requirements: 'Recommended dimensions are: %{dimensions} px',
  },
};
