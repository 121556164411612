<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <v-form @submit.prevent="handleSubmit(submit)">
      <div class="form-wrapper">
        <BackendErrors :backend-errors="backendErrors" />
        <BaseRichTextEditor
          v-model="formData.body"
          rules="min:3|max:60000|required"
          :label="$t('gamePage.create.body')"
          :variables-list="variablesList"
          extended-version
        />
        <div class="pt-2 pb-12">
          <p>{{ $t('gamePage.create.variables') }}:</p>
          <span
            v-for="(variableName, index) in variablesList"
            :key="`list-item-${index}`"
            class="d-block subtitle-2"
          >
            {{ variableName }}
          </span>
        </div>
        <div
          v-for="(field, index) in formData.additionalFields"
          :key="`additional-fields-item-${index}`"
        >
          <BaseInput
            v-model="field.value"
            rules="min:3|max:255|required"
            :label="field.display_name"
          />
        </div>
        <BaseImageUploader
          v-if="pageHasSideImage"
          v-model="formData.sideImage"
          :rules="imageValidationRules()"
          :label="$t('gamePage.create.side_image')"
          :vid="$t('gamePage.create.side_image')"
        />
        <v-alert
          v-if="pageHasSideImage"
          icon="mdi-information-outline"
          color="grayDark"
          text
        >
          <span class="body-2">
            {{ $t('gamePage.create.side_image_requirements', {dimensions: sideImageDimensions}) }}
          </span>
        </v-alert>
      </div>
      <EditButtons
        :edit-mode="editMode"
        :is-sending="isSending"
        @submit="submit"
        @cancel="cancel"
      />
    </v-form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import BackendErrors from '@/components/Dashboard/Partials/BackendErrors/BackendErrors';
import BaseRichTextEditor from '@/components/Form/BaseRichTextEditor';
import BaseInput from '@/components/Form/BaseInput';
import BaseImageUploader from '@/components/Form/BaseImageUploader';
import EditButtons from '@/components/Dashboard/Partials/EditButtons/EditButtons';
import { END_OF_TIME, PRIVACY_POLICY, COOKIES_POLICY } from '@/names/gamePageTypes.names';
import { DELETE } from '@/names/global.names';

export default {
  name: 'GamePageForm',
  components: {
    ValidationObserver,
    EditButtons,
    BackendErrors,
    BaseRichTextEditor,
    BaseInput,
    BaseImageUploader,
  },
  props: {
    gamePage: {
      type: Object,
      default: () => {},
    },
    variablesList: {
      type: Array,
      default: () => [],
    },
    backendErrors: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        displayName: '',
        body: '',
        additionalFields: [],
        sideImage: null,
      },
    };
  },
  computed: {
    pageHasSideImage() {
      return ![PRIVACY_POLICY, COOKIES_POLICY].includes(this.formData.displayName);
    },
    sideImageDimensions() {
      switch (true) {
        case this.formData.displayName === END_OF_TIME:
          return '326x633';
        default:
          return '547x1080';
      }
    },
  },
  created() {
    this.formData.displayName = this.gamePage.display_name;
    this.formData.body = this.gamePage.body;
    this.formData.additionalFields = this.gamePage.additional_fields;
    this.formData.sideImage = this.gamePage.side_image_url;
  },
  methods: {
    cancel() {
      this.$emit('handle-cancel');
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }
      this.$emit('handle-submit', this.baseFormData());
    },
    baseFormData() {
      const formData = new FormData();
      const { gameId, pageId } = this.$route.params;

      formData.set('_method', 'PUT');
      formData.set('body', this.formData.body);
      this.formData.additionalFields.map((additionalField, index) => {
        formData.set(`additional_fields[${index}][name]`, additionalField.name);
        formData.set(`additional_fields[${index}][value]`, additionalField.value);

        return additionalField;
      });
      formData.set('side_image', this.pageHasSideImage && this.formData.sideImage !== DELETE ? this.formData.sideImage : '');

      return { gameId, pageId, params: formData };
    },
    imageValidationRules() {
      return `${
        !this.editMode || (this.formData.sideImage === DELETE && this.editMode)
          ? 'image_required|'
          : ''
      }size:10240|extensions:jpeg,jpg,png,gif`;
    },
  },
};
</script>
